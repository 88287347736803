// File: custom-renderer.js
// Import Highway
import Highway from '@dogstudio/highway';

// import Map from '../../routes/map';
import Common from '../../routes/common';

class CustomRenderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() { }
  onLeave() {  }
  onEnterCompleted() { 
    Common.init();
    // Map.init();
  }
  onLeaveCompleted() {  }
}

// Don`t forget to export your renderer
export default CustomRenderer;